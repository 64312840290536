<template>
  <b-container fluid class="accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.png"></b-img>
      </b-col>
      <b-col cols="11" class="my-profile-account">
        <b-row>
          <b-col cols="11">
            <h2>{{$t("profile.infotexts.mypofile-title")}}</h2>
            <p>
              {{$t("profile.infotexts.mypofile")}}
            </p>
            <hr />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <div v-if="loading" class="loader-box">
             <loading :loading="true" />
        </div>
        <b-row>
            <b-col cols="2" class="text-center">
                <!--jimir3x-->
                <img :src="logoPreview" class="mb-3 profile-settings-profile-company__logoPreview"  />
                <!--jimir3x-->
                </b-avatar>
                <b-row>
                    <b-col cols="12">
                        <b-form-file
                            id="logo"
                            class="inputfile"
                            v-model="form.logo"
                            accept="image/jpeg, image/jpg, image/png"
                        >
                        </b-form-file>
                        <!--jimir3x-->
                        <div @click="clickReplaceLogo" class="replace-text ml-5 profile-settings-profile-company__replace-logo">
                            <v-icon icon="upload" class="mr-2" /> 
                            <span>{{$t("buttons.replace-logo")}}</span>
                        </div>
                        <!--jimir3x-->
                    </b-col>
                </b-row>
          </b-col>
          <!--jimir3x-->
          <b-col cols="9">
          <!--jimir3x-->
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <form action="#" @submit.prevent="handleSubmit(save)">
                    <validation-provider
                        mode="passive"
                        name="company-name"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                    >
                        <div class="form-group">
                            <label for="cname">{{$t("profile.contents.myprofile.company.name")}}</label>
                            <input spellcheck="true"
                                id="cname"
                                name="Company Name"
                                type="text"
                                class="form-control custom"
                                v-model="form.sc.name"
                                autocomplete="off"
                                @keyup="() => $refs.companySearch.setVisible(true)"
                            />
                            <company-search 
                                ref="companySearch"
                                v-model="form.sc" 
                                :text="form.sc.name" 
                            />
                            <div v-if="validationContext.errors[0]" class="text-danger">
                            <i class="imgs icon-warning"></i>
                                {{ validationContext.errors[0] }}
                            </div>
                        </div>
                    </validation-provider>
                    <validation-provider
                        name="Web Address"
                        :rules="{ required: true, regex: /((?:[\w+]+\:\/\/)?(?:[\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?)/i }"
                        v-slot="validationContext"
                    >
                        <div class="form-group">
                            <label for="webaddress">{{$t("profile.contents.myprofile.company.web")}}</label>
                            <input spellcheck="true"
                                id="webaddress"
                                type="text"
                                name="Web Address"
                                class="form-control custom"
                                v-model="form.website"
                            />
                            <div v-if="validationContext.errors[0]" class="text-danger">
                            <i class="imgs icon-warning"></i>
                                {{ validationContext.errors[0] }}
                            </div>
                        </div>
                    </validation-provider>
                     <validation-provider
                        name="Number Of Employees"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                    >
                        <div class="form-group">
                            <label for="webaddress">{{$t("profile.contents.myprofile.company.noe")}}</label>
                            <multi-select
                                placeholder="Number Of Employees"
                                :showLabels="false"
                                :clearOnSelect="false"
                                :allowEmpty="false"
                                label="title"
                                v-model="form.number_of_employees"
                                :options="number_of_employees"
                            >
                            </multi-select>
                            <div v-if="validationContext.errors[0]" class="text-danger">
                            <i class="imgs icon-warning"></i>
                                {{ validationContext.errors[0] }}
                            </div>
                        </div>
                    </validation-provider>
                    <validation-provider
                        name="Location"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                    >
                        <div class="form-group">
                            <label for="location">{{$t("profile.contents.myprofile.company.location")}}</label>
                            <mapbox-geocoder
                                v-if="locationEditMode"
                                class="form-input"
                                placeholder="Search Location"
                                access-token="pk.eyJ1IjoiYmVubnl0cm92YXRvIiwiYSI6ImNrZDcwdTVwbTE4amEyem8yZWdkNHN3ZmoifQ.r3Llqtnwfqqju2zfzE-fvA"
                                types="region,district,place,postcode,locality,neighborhood"
                                @mb-result="geocoderOnResult"
                            />
                            <b-input-group 
                                v-else
                                style="
                                    border: 2px solid #dfe4ea;
                                    box-sizing: border-box;
                                    border-radius: 6px;
                                "
                            >
                                <b-form-input 
                                    readonly
                                    style="border: none; background: #ffffff !important" 
                                    :value="
                                        $_.size(form.location.address) > 0
                                            ?
                                            form.location.address.state +
                                            ',' +
                                            form.location.address.city +
                                            ',' +
                                            form.location.address.country
                                            : 'Select Place'
                                    " 
                                    class="form-control custom"
                                >
                                </b-form-input>
                                <b-input-group-append>
                                    <b-button 
                                        @click="() => (locationEditMode = true)" 
                                        class="edit-button "
                                       
                                        id="dropdown-right"
                                        variant="none"
                                    >
                                        <img
                                            src="/img/profile-edit-location-icon.svg"
                                            class="img-fluid mr-2 "
                                            alt=""
                                            style="margin-top: -2px"
                                        />
                                        {{$t("buttons.edit")}}
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <div v-if="validationContext.errors[0]" class="text-danger">
                            <i class="imgs icon-warning"></i>
                                {{ validationContext.errors[0] }}
                            </div>
                        </div>
                    </validation-provider>
                    <validation-provider
                        name="Company Desription"
                        :rules="{ required: true, min: 120, max: 1200 }"
                        v-slot="validationContext"
                    >
                        <div class="form-group">
                            <label for="webaddress">{{$t("profile.contents.myprofile.company.desc")}}</label>
                            <b-form-textarea
                                placeholder="Company Description"
                                :rows="8"
                                v-model="form.description"
                            >
                            </b-form-textarea>
                             <div class="counter">
                            <div v-if="validationContext.errors[0]" class="text-danger">
                            <i class="imgs icon-warning"></i>
                                {{ validationContext.errors[0] }}
                            </div>
                            <div class="number-back-counter">
                                {{ counter }} / 1200
                            </div>
                            </div>
                        </div>
                    </validation-provider>
                     <div class="dropzone-content mb-34">
                        <vue-dropzone
                            @vdropzone-files-added="fileUpload"
                            ref="myVueDropzone"
                            id="dropzone"
                            :options="dropzoneOptions"
                            :useCustomSlot="true"
                        >
                            <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">
                                    {{$t("myquotes.addimg-title")}}
                                </h3>
                                <small class="text-muted d-block">{{$t("myquotes.or")}}</small>
                                <button @click.prevent class="btn btn-primary">
                                    {{$t("buttons.addimage")}}
                                </button>
                                <p>JPG,PNG, MP4, WEBM 5MB {{$t("myquotes.max")}}</p>
                            </div>
                        </vue-dropzone>
                        <div  
                            style="margin-bottom:0px; position:relative;" 
                            class="img-preview-content-company mt-3 ml-0"
                         >
                            <b-progress
                                v-if="uploadingProgress !== null"
                                :value="uploadingProgress"
                                :max="100"
                                variant="primary"
                                show-progress
                                animated
                                class="mb-3"
                            >
                            </b-progress>

                            <div 
                                v-for="(media, index) in mediaPreviews" 
                                :key="index" 
                                class="item mr-3 mt-3 float-left"
                                style="margin-left:0px;  margin-botton:30px; position:relative;"
                            >
                                <div @click.prevent="deleteMedia(index)" class="clearBtn">
                                    x
                                </div>
                                    <img
                                        v-if="['jpeg', 'jpg', 'png'].includes(media.ext)"
                                        :src="media.preview"
                                        width="100"
                                    />
                                    <video
                                        v-else-if="['webm', 'mp4'].includes(media.ext)"
                                        width="100"
                                        height="100"
                                        controls="none"
                                    >
                                        <source :src="media.preview" />
                                        {{$t("messenger.video-alert")}}
                                    </video>
                                    <audio v-else-if="['mp3'].includes(media.ext)" controls>
                                        <source
                                            :src="media.preview"
                                            type="audio/mpeg"
                                    />
                                        {{$t("messenger.audio-alert")}}
                                    </audio>
                                    <img v-else src="@/assets/img/file.png" width="100" />
                            </div>

                            
                        </div>
                    </div>

                
                    <div class="" style="">
                        <!-- <button 
                            class="btn btn-primary "
                            type="submit"
                            style="margin-top: 50px; width:80%; opacity:0; cursor:initial;"
                        >
                            {{$t("buttons.savechanges")}}
                        </button> -->

                         <button 
                            class="btn btn-primary "
                            type="submit"
                            style="margin-top: 50px;width:100%;"
                        >
                            {{$t("buttons.savechanges")}}
                        </button>
                    </div> 
                  
                    
                    
                </form>
                

              </validation-observer>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <sweet-modal class="modal-x" id="profileLocation" ref="locationModal">
        <h1>{{$t("profile.contents.myprofile.aboutme.modal-title")}}</h1>
        <hr style="margin-top: -5px; margin-bottom: 24px" />
        <mapbox-map
            ref="selectmap"
            style="height: 450px"
            v-if="form.location.center"
            :access-token="this.mapAccessToken"
            :map-style="this.mapBoxStyle"
            :center="form.location.center"
            :zoom="13"
            @mb-created="map => (selectMap = map)"
            @mb-drag="() => (markerpos = selectMap.getCenter().toArray())"
            @mb-dragend="dragEndFunction"
        >
            <mapbox-marker
                :lng-lat="markerpos"
                :draggable="false"
                color="blue"
            />
        </mapbox-map>
        <div class="d-flex my-4 justify-content-end">
            <button
                :disabled="!placeSelected"
                @click="selectPlace()"
                class="btn btn-primary"
            >
            {{$t("buttons.select")}}
            </button>
        </div>
    </sweet-modal>
  </b-container>
</template>

<script>
import LOADING from "@/components/global/loading";
import UPLOAD_MEDIA_ITEMS from '@/graphql/me/profile/uploadMediaItems.graphql';
import UPDATE_COMPANY from '@/graphql/me/profile/updateCompany.graphql';
import GET_COMPANY from '@/graphql/me/profile/getCompany.graphql';
import companySearch from '@/components/global/companySearch.vue';
import _ from 'lodash';
export default {
  components: {
    loading: LOADING,
    companySearch
  },
  data() {
    return {
        form: {
            sc: {
                name: null,
                logo: null,
                key: null
            },
            website: null,
            number_of_employees: null,
            location: {},
            description: null,
            media: [],
            logo: null
        },
        selectMap: null,
        loading: false,
        markerpos: [],
        uploadingProgress: null,
        locationEditMode: false,
        placeSelected: false,
        dropzoneOptions: {
            createImageThumbnails: true,
            addRemoveLinks: true,
            autoProcessQueue: false,
            url: "https://httpbin.org/post",
            thumbnailWidth: null,
            thumbnailHeight: null,
            acceptedFiles: "image/*, video/mp4, video/webm",
            maxFilesize: 100
      },
    };
  },
  computed: {
      mediaPreviews() {
            if (_.size(this.form.media) === 0) {
                return [];
            }

            let previews = [];
            for (let media of this.form.media) {    
                switch (media.ext) {
                    case 'jpeg':
                    case 'jpg':
                    case 'png':
                        previews.push(
                            {
                                name: media.filename,
                                preview: this.CDN_IMAGE_PREFIX + media.code,
                                ext: media.ext,
                            }
                        );
                        break;
                    case 'webm':
                    case 'mp4':
                    case 'mpeg':
                        previews.push(
                            {
                                name: media.filename,
                                preview: this.CDN_VIDEO_PREFIX + media.code,
                                ext: media.ext,
                            }
                        );
                        break;

                    default:
                        previews.push(
                            {
                                name: media.filename,
                                preview: require("@/assets/img/file.png"),
                                ext: media.ext,
                            }
                        );
                        break;
                }
            }

        return previews;
      },
      logoPreview() {
        if (_.isString(this.form.logo)) {
            return this.CDN_IMAGE_PREFIX + this.form.logo;
        } else if (this.form.logo instanceof File) {
            return URL.createObjectURL(this.form.logo);
        } else {
            return '/img/company-logo.svg';
        }
      },
      counter() {
        return _.size(this.form.description);
      }
  },
  methods: {
      geocoderOnResult({ result }) {
          this.form.location = result;
          this.markerpos = result.center;
          this.$refs.locationModal.open();
      },
      selectPlace() {
          this.$refs.locationModal.close();
          this.form.location.center = this.markerpos;
      },
      dragEndFunction() {
          this.placeSelected = true;
      },
      async fileUpload(files) {
        if (_.size(this.form.media) > 18) {
            this.$bvToast.toast(this.$t("Toaster.company18"), 
                {
                    title: this.$t("Toaster.error-title"),
                    autoHideDelay: 1350,
                }
            );

            return false;
        }
        let options = {
            fetchOptions: {
                onUploadProgress: progress => {
                    this.uploadingProgress = Math.round(
                        (progress.loaded / progress.total) * 100
                    );
                }
            },
        };

        let selector = await this.$apollo.mutate(UPLOAD_MEDIA_ITEMS, { files }, options);
        let items = selector('**.upload');
        for (let item of items) {
            if (item.uploaded === false) {
                this.$bvToast.toast(
                    this.$t("ERROR.COMPANY_UPLOAD_FAILED",{values:{filename:item.filename}}),
                    
                    {
                         title: this.$t("Toaster.error-title"),
                        autoHideDelay: 1350,
                    }
                );
                continue;
            }

            this.form.media.push(item);
        }

        this.uploadingProgress = null;
      },
      deleteMedia(index) {
          this.form.media.splice(index, 1);
      },
      clickReplaceLogo() {
          document.querySelector('#logo').click();
      },

      async save() {
          try {
              this.loading = true;
              let logo = this.form.logo;
              if (!(logo instanceof File)) {
                  logo = null;
              }

              let params = {
                  name: this.form.sc.name,
                  key: this.form.sc.key,
                  website: this.form.website,
                  logo: logo,
                  number_of_employees: _.get(this, 'form.number_of_employees.id'),
                  description: this.form.description,
                  location: {
                      lon: this.form.location.center[0],
                      lat: this.form.location.center[1]
                  },
                  media: _.map(
                      this.form.media, 
                      m => (
                          { 
                              code: m.code, 
                              filename: m.filename, 
                              ext: m.ext 
                          }
                      )
                  )
              };

              await this.$apollo.mutate(UPDATE_COMPANY, { company: params });
              this.$bvToast.toast(this.$t("Toaster.settings-updated"), 
                  {
                       title: this.$t("Toaster.success-title"),
                      variant: 'success',
                      autoHideDelay: 1350,
                 }
              );
          } catch (error) {
                return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
          } finally {
              this.loading = false;
          }
      },

      async get() {
          this.loading = true;
          let selector = await this.$apollo.query(GET_COMPANY);
          let form = selector('**.get');
          this.form.logo = form.logo;
          this.form.sc.name = _.get(form, 'name');
          this.form.sc.key = _.get(form, 'key');
          this.form.website = _.get(form, 'website');
          this.form.description = _.get(form, 'description');
          this.markerpos = this.form.location.center = [_.get(form, 'location.point.lon', 10), _.get(form, 'location.point.lat', 10)];
          this.form.location.address = _.get(form, 'location.address', { });
          let noe = _.get(form, 'number_of_employees.low',0) + '-' + _.get(form, 'number_of_employees.high',0);
          _.set(this, 'form.number_of_employees.id', noe);
          _.set(this, 'form.number_of_employees.title', noe);

          this.form.media = form.media || [];
          this.loading = false;
      }

  },
  async created() {
      this.get();
  },
};
</script>